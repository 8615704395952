

export default {
  SET_STATUS(state, status){
    state.orderStatus = status;
  },
  SET_STORES(state, stores){
    state.stores = stores;
  },
  SET_STORES_WITH_LIMITS(state, payload){
    state.storeWithLimits = payload;
  },
  SET_GENERAL_SETTING(state, data){
    state.generalSetting = data
  },
  SET_IMAGES_STORES(state, data){
    state.getImageSlider = data
  }
};
